import * as React from 'react';
import { useAtomValue, useUpdateAtom, useResetAtom } from 'jotai/utils';
import { isEqual } from 'lodash';

import {
    isPickListSectionDisabledAtom,
    isChangingAttributeTypeAtom,
    newPicklistRowValueAtom,
    attributeConfigurationModalVisibleAtom,
    attributeSettingsAtom,
    isDependencySectionDisabledAtom,
    isDependencySectionVisibleAtom,
    attributeFormModeAtom,
    attributesListAtom,
    attributeSettingsInitialValueAtom,
    nextAttributeIdOfUnsavedChangesModalAtom,
} from 'atons/AttributeManagement/atoms';
import CustomFieldsAdapter from 'adapters/CustomFieldsAdapter';

import { Modal, TabList, AutoComplete } from 'components';
import { alphabeticalOrder } from 'shared/helpers';

import AttributeSettings from './AttributeSettings/AttributeSettings';
import PicklistSettings from './PicklistSettings/PicklistSettings';
import DependencySettings from './DependencySettings/DependencySettings';
//import DependencyTactic from './DependencyTactic/DependencyTactic';

import styles from './AttributeConfigurationModal.module.scss';
import { queryClient } from 'react-query/queryClient';
import { queryKeys } from 'react-query/constants';
import { useAtom } from 'jotai';

import { useGetCustomFieldDetail } from 'react-query/queries';

const TabBars = {
    AttributeSettings: 'attribute-settings',
    PicklistSettings: 'picklist-settings',
    DependencySettings: 'dependency-settings',
    DependencyTactic: 'dependency-tactic',
};

/**
 *
 * @returns
 */
const AttributeConfigurationModal = () => {
    const [selected, setSelected] = React.useState(0);
    const [show, setShow] = useAtom(attributeConfigurationModalVisibleAtom);
    const [selectedTab, setSelectedTab] = React.useState(TabBars.AttributeSettings);
    const [attributeSettings, setAttributeSettings] = useAtom(attributeSettingsAtom);
    const [attributeSettingsInitialValue, setAttributeSettingsInitialValue] = useAtom(
        attributeSettingsInitialValueAtom
    );
    const attributeFormMode = useAtomValue(attributeFormModeAtom);
    const isPickListSectionDisabled = useAtomValue(isPickListSectionDisabledAtom);
    const isDependencySectionVisible = useAtomValue(isDependencySectionVisibleAtom);
    const isDependencySectionDisabled = useAtomValue(isDependencySectionDisabledAtom);
    const attributesList = useAtomValue(attributesListAtom);
    const setIsChangingAttributeTypeAtom = useUpdateAtom(isChangingAttributeTypeAtom);
    const setNextAttributeIdOfUnsavedChangesModal = useUpdateAtom(nextAttributeIdOfUnsavedChangesModalAtom);
    const resetPicklistNewItemRow = useResetAtom(newPicklistRowValueAtom);
    const resetAttributeSettingsAtom = useResetAtom(attributeSettingsAtom);
    const resetAttributeSettingsInitialValueAtom = useResetAtom(attributeSettingsInitialValueAtom);
    const resetAttributesListAtom = useResetAtom(attributesListAtom);

    const onCloseAttributeSettingsModal = React.useCallback(() => {
        queryClient.invalidateQueries([queryKeys.attributeFieldsList]);
        queryClient.invalidateQueries([queryKeys.customFieldDetail]);
        setIsChangingAttributeTypeAtom(false);
        setSelectedTab(TabBars.AttributeSettings);
        resetPicklistNewItemRow();
        resetAttributeSettingsAtom();
        resetAttributeSettingsInitialValueAtom();
        setShow(false);
    }, [
        setIsChangingAttributeTypeAtom,
        resetPicklistNewItemRow,
        resetAttributeSettingsAtom,
        setShow,
        resetAttributeSettingsInitialValueAtom,
    ]);

    /**
     * @param {number} customFieldId Value selected by the user
     */
    const switchCustomField = React.useCallback(customFieldId => {
        setSelected(customFieldId);
        onChangeTab({ id: TabBars.AttributeSettings });
    }, []);

    const tabs = React.useMemo(() => {
        const sections = [
            {
                id: TabBars.AttributeSettings,
                label: 'Attribute Settings',
                children: (
                    <AttributeSettings
                        closeModalFunc={onCloseAttributeSettingsModal}
                        switchCustomField={switchCustomField}
                    />
                ),
            },
        ];

        if (!isPickListSectionDisabled)
            sections.push({
                id: TabBars.PicklistSettings,
                label: 'Picklist Settings',
                children: <PicklistSettings />,
                disabled: isPickListSectionDisabled,
            });

        if (!isDependencySectionVisible)
            sections.push({
                id: TabBars.DependencySettings,
                label: 'Dependency Settings',
                children: <DependencySettings closeModalFunc={onCloseAttributeSettingsModal} />,
                disabled: isDependencySectionDisabled,
                title: attributeSettings.canHaveParent
                    ? isDependencySectionDisabled
                        ? 'You must have at least 1 active picklist option in order to access this tab'
                        : undefined
                    : "This attribute type it's not allowed to use that feature",
            });

        return sections;
    }, [
        isPickListSectionDisabled,
        isDependencySectionVisible,
        onCloseAttributeSettingsModal,
        isDependencySectionDisabled,
        attributeSettings.canHaveParent,
        switchCustomField,
    ]);

    const { refetch: fetchCustomFieldDetail } = useGetCustomFieldDetail(Number(selected), {
        onSuccess: res => {
            const attributeSettings = CustomFieldsAdapter.toAttributeSetting(res);
            setAttributeSettings(attributeSettings);
            setAttributeSettingsInitialValue(attributeSettings);
        },
    });

    const onChangeTab = ({ id }) => setSelectedTab(id);

    const currentSelectedTab = ({ id }) => id === selectedTab;

    /**
     * @param {string} value DropDown selected value
     * @return {void}
     */
    const onSwitchCustomFieldDetails = value => {
        const formattedValue = Number(value);
        if (formattedValue === attributeSettings.id) return;
        if (isEqual(attributeSettings, attributeSettingsInitialValue)) {
            return switchCustomField(formattedValue);
        }
        setNextAttributeIdOfUnsavedChangesModal(formattedValue);
    };

    React.useEffect(() => {
        selected !== 0 && fetchCustomFieldDetail();
    }, [selected, fetchCustomFieldDetail]);

    React.useEffect(() => {
        queryClient.invalidateQueries([queryKeys.getCustomFieldPicklist]);
    }, [selectedTab]);

    React.useEffect(() => {
        return () => {
            resetAttributesListAtom();
        };
    }, [resetAttributesListAtom]);
    return (
        <>
            <Modal
                title="Attribute Configuration"
                show={show}
                closeBtn
                closeBtnFunc={onCloseAttributeSettingsModal}
                tabModal
                className={styles.attributeConfigurationModal}
            >
                {attributeFormMode === 'edit' && (
                    <div className={styles.selectedAttr}>
                        <b>Selected Attribute</b>

                        <AutoComplete
                            placeholder="Select Attribute"
                            value={Number(attributeSettings.id)}
                            onChange={onSwitchCustomFieldDetails}
                            options={alphabeticalOrder(attributesList, 'name')
                                .filter(attr => attr.isDeleted === false)
                                .map(attr => ({ label: attr.name, value: attr.id }))}
                            autoComplete="true"
                            className={styles.attrSelection}
                        />
                    </div>
                )}

                <TabList
                    selectedTabId={selectedTab}
                    onChange={onChangeTab}
                    tabs={tabs}
                />

                <div
                    id="attribute-configuration-modal"
                    className={styles.modalContent}
                    role="tabpanel"
                >
                    {tabs.find(currentSelectedTab)?.children}
                </div>
            </Modal>
        </>
    );
};

export default AttributeConfigurationModal;
